<template>
  <div class="pageContol">
    <!-- 列表部分 -->
    <div class="framePage">
      <!-- 页面位置 -->
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">问卷活动管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <!-- 操作区 -->
        <div class="operationControl flexcb" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="问卷调查名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem">问卷调查名称:</span>
              <el-input
                v-model="paperName"
                type="text"
                size="small"
                placeholder="请输入问卷调查名称"
                clearable
              />
            </div>

            <div title="问卷调查主题" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem">问卷调查主题:</span>
              <el-input
                  v-model="paperTheme"
                  type="text"
                  size="small"
                  placeholder="请输入问卷主题名称"
                  clearable
              />
            </div>

            <div title="状态" class="searchboxItem ci-full">
              <span class="itemLabel2">状态:</span>
              <el-select
                v-model="status"
                clearable
                placeholder="请选择状态"
                size="small"
              >
                <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
                <!-- <el-option  label="已认证" value="20"></el-option>
                <el-option label="未认证" value="10"></el-option>-->
              </el-select>
            </div>
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
          <div>
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="addPaper()"
              >新增问卷活动</el-button
            >
          </div>
        </div>

        <!-- 列表 -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
                width="100px"
              />
              <el-table-column
                label="问卷调查名称"
                align="left"
                prop="paperName"
                show-overflow-tooltip
                width="280"
                fixed
              />
              <el-table-column
                label="问卷调查主题"
                align="left"
                prop="paperTheme"
                show-overflow-tooltip
                width="150"
                fixed
              />
              <el-table-column
                label="问卷说明"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.paperDescription || "--"
                }}</template>
              </el-table-column>

              <el-table-column
                label="截止日期"
                align="left"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">{{
                  scope.row.endDate | momentDate
                }}</template>
              </el-table-column>
              <el-table-column
                label="创建时间"
                align="left"
                show-overflow-tooltip
                min-width="150"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="反馈人数"
                align="left"
                prop="feedback"
                show-overflow-tooltip
              />

              <el-table-column label="状态" align="left" show-overflow-tooltip>
                <template slot-scope="scope">{{
                    scope.row.paperType == 10
                        ? "进行中"
                        : scope.row.paperType == 20
                        ? "已结束"
                        : scope.row.paperType == "00"
                            ? "未开始"
                            : "--"
                }}</template>
              </el-table-column>

              <el-table-column
                label="是否发布"
                align="center"
                show-overflow-tooltip
                prop="published"
                width="120"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-switch
                    class="selStyle"
                    v-model="scope.row.published"
                    width="40"
                    @change="getSwitch(scope)"
                  ></el-switch>
                </template>
              </el-table-column>

              <el-table-column
                label="操作"
                fixed="right"
                align="center"
                width="200px"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="showPaper(scope.row)"
                    >查看</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="deletePaper(scope.row)"
                    >删除</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="surveySummary(scope.row)"
                    >调查汇总</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="feedbackRecord(scope.row.paperActivityId)"
                    >反馈记录</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>

        <!-- 分页 -->
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>

    <!-- 新增/查看 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogFormVisible"
      width="700px"
      @close="closeModel"
    >
      <el-form
        ref="groupForm"
        :model="groupForm"
        style="width: 100%"
        :rules="rules"
      >
        <el-form-item label="问卷主题名称：" label-width="120px" prop="paperTheme">
          <el-input
              type="text"
              placeholder="请输入问卷主题"
              v-model="groupForm.paperTheme"
              maxlength="250"
              :disabled="dialogType"
              style="width: 400px"
          ></el-input>
        </el-form-item>

        <el-form-item label="问卷调查名称：" label-width="120px" prop="paperId">
            <el-select
              v-model="groupForm.paperId"
              placeholder="请选择"
              style="width: 400px"
              :disabled="dialogType"
              filterable
            >
              <el-option
                v-for="(item, index) in questionnaireList"
                :key="index"
                :label="item.paperName"
                :value="item.paperId"
                style="width:400px"
              >
              </el-option>
            </el-select>
        </el-form-item>

        <el-form-item label="问卷有效期：" label-width="120px" prop="timeLimit">
          <div class="flex">
            <el-form-item>
              <div>
                <el-radio
                  v-model="groupForm.timeLimit"
                  label="1"
                  :disabled="dialogType"
                  >无限期</el-radio
                >
                <el-radio
                  v-model="groupForm.timeLimit"
                  label="2"
                  :disabled="dialogType"
                  >截止期</el-radio
                >
              </div>
            </el-form-item>
            <el-form-item
              v-if="groupForm.timeLimit == 2"
              style="width: 200px; margin-left: 10px"
              prop="endDate"
            >
              <el-date-picker
                v-model="groupForm.endDate"
                type="date"
                placeholder="选择日期"
                value="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :disabled="dialogType"
                :rules="[
                  {
                    required: true,
                    message: '请输入问卷描述',
                    trigger: 'blur',
                  },
                ]"
              >
              </el-date-picker>
            </el-form-item>
          </div>
        </el-form-item>

        <el-form-item label="推送对象：" label-width="120px" prop="pushType">
          <div class="flex">
            <el-form-item>
              <el-radio
                v-model="groupForm.pushType"
                label="10"
                :disabled="dialogType"
                >全部用户</el-radio
              >
              <el-radio
                v-model="groupForm.pushType"
                label="20"
                :disabled="dialogType"
                >指定班级</el-radio
              >
            </el-form-item>
            <el-form-item
              v-if="groupForm.pushType == 20"
              style="min-width: 300px; margin-left: 10px;"
              prop="projectIdList"
            >
              <el-select
                v-model="groupForm.projectIdList"
                multiple
                filterable
                placeholder="请选择"
                :disabled="dialogType"
              >
                <el-option
                  v-for="(item, index) in gradeList"
                  :key="index"
                  :label="item.projectName"
                  :value="item.projectId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="closeModel">取 消</el-button>
        <el-button class="bgc-bv" type="primary" @click="doOk">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";

export default {
  name: "operate/Testquestionbank",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      dialogType: false, // 弹窗状态
      dialogTitle: "", // 弹窗标题
      questionnaireList: [], // 问卷列表
      projectIdList: [], // 选中的班级列表
      gradeList: [], // 班级列表
      organName: "", // 机构名称
      paperName: "", // 问卷名称
      paperTheme: "", // 问卷主题
      dialogFormVisible: false,
      groupForm: {
        paperId: "", // 问卷id
        timeLimit: "1", // 问卷有效期状态
        endDate: "", // 截止日期
        pushType: "10", // 推送状态
        projectIdList: [], // 班级id列表
        paperTheme: "", // 问卷主题
      },
      // 验证
      rules: {
        paperId: [{ required: true, message: "请选择问卷", trigger: "blur" }],
        paperTheme: [{ required: true, message: "请输入问卷主题", trigger: "blur" }],
        timeLimit: [
          { required: true, message: "请选择问卷的期限", trigger: "blur" },
        ],
        endDate: [
          { required: true, message: "请选择截止日期", trigger: "blur" },
        ],
        pushType: [
          { required: true, message: "请选择推送方式", trigger: "blur" },
        ],
        projectIdList: [
          { required: true, message: "请选择班级", trigger: "blur" },
        ],
      },
      status: "0", // 状态
      // 状态列表
      statusList: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "00",
          label: "未开始",
        },
        {
          value: "10",
          label: "进行中",
        },
        {
          value: "20",
          label: "已结束",
        },
      ],
      paperActivityId: '',
    };
  },
  created() {
    this.getQuestionnaireList();
    this.getGradeList();
  },
  computed: {},
  methods: {
    // 获取班级列表
    getGradeList() {
      this.$post("/edu/project/paper/project", {
        paperId: "",
      }).then((ret) => {
        if (ret.status == "0") {
          this.gradeList = ret.data;
        }
      });
    },
    // 获取问卷列表
    getQuestionnaireList() {
      this.$post("/edu/project/paper/activity").then((ret) => {
        if (ret.status == "0") {
          this.questionnaireList = ret.data;
        } else {
          this.$message({
            message: "问卷列表获取失败! 请重试",
            type: "success",
          });
        }
      });
    },

    // 跳转反馈记录
    feedbackRecord(paperActivityId) {
      this.$router.push({
        path: "/web/questionnaire/feedbackRecord",
        query: {
          paperActivityId: paperActivityId
        },
      });
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.paperName) {
        params.paperName = this.paperName;
      }
      if (this.status) {
        params.paperType = this.status;
      }
      if (this.paperTheme) {
        params.paperTheme = this.paperTheme;
      }
      this.doFetch({
        url: "/edu/project/paper/pageList",
        params,
        pageNum,
      });
    },

    // 点击新增问卷活动
    addPaper() {
      this.dialogFormVisible = true;
      this.dialogType = false;
      this.dialogTitle = "新增问卷活动";
      this.paperActivityId = '';
      // 新增时初始化数据
      this.groupForm = {
        paperId: "", // 问卷id
        paperName: "", // 问卷名称
        paperTheme: "", // 问卷主题
        timeLimit: "1", // 问卷有效期状态
        endDate: "", // 截止日期
        pushType: "10", // 推送状态
        projectIdList: [], // 班级id列表
      };
    },
    //弹框确定
    doOk() {
      if (this.dialogType) {
        this.closeModel();
        return;
      }
      this.$refs["groupForm"].validate((valid) => {
        if (valid) {
          let params = {
            pushType: this.groupForm.pushType, // 推送状态
            projectIdList: this.groupForm.projectIdList, // 班级id列表
            paperId: this.groupForm.paperId, // 问卷id
            paperTheme: this.groupForm.paperTheme, // 问卷主题
            paperActivityId: this.paperActivityId // 问卷ID
          };
          if (this.groupForm.endDate && this.groupForm.timeLimit === '2') {
            params.endDate = this.groupForm.endDate; // 截止日期
          }
          this.$post("/edu/project/paper/insert", params).then((ret) => {
            if (ret.status == "0") {
              this.$message({
                message: ret.message,
                type: "success",
              });
              this.closeModel();
              this.getData(-1);
            }
          });
        }
      });
    },
    //弹框关闭
    closeModel() {
      this.$refs["groupForm"].resetFields();
      this.dialogFormVisible = false;
    },
    getSwitch(scope) {
      this.$post("/edu/project/paper/published", {
        paperActivityId: scope.row.paperActivityId,
        published: scope.row.published
      })
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              type: "success",
              message: "修改成功",
            });
            this.getData(-1);
          }
        })
        .catch((err) => {
          return;
        });
    },
    deletePaper(row) {
      let message = '你确定要删除该问卷吗?';
      if (row.feedback > 0) {
        message = '该问卷调查已有学员参加问卷,是否继续删除?';
      }
      this.doDel({
        url: "/edu/project/paper/delete",
        msg: message,
        ps: {
          type: "post",
          data: {paperActivityId: row.paperActivityId}
        },
      });
    },
    // 点击查看
    showPaper(row) {
      this.dialogFormVisible = true;

      this.paperActivityId = row.paperActivityId;
      this.dialogType = row.published;
      this.dialogTitle = "查看问卷活动";
      this.groupForm.paperName = row.paperName;
      this.groupForm.paperId = row.paperId;
      this.groupForm.paperTheme = row.paperTheme;
      if (row.endDate) {
        this.groupForm.endDate = row.endDate.replaceAll('/','-');
        this.groupForm.timeLimit = "2";
      } else {
        this.groupForm.endDate = ''
        this.groupForm.timeLimit = "1";
      }
      this.getGradeList2(row);
    },
    //调查汇总
    surveySummary(row) {
        this.$router.push({
        path: "/web/questionnaire/questionnaireSurveySummary",
        query: {
          editInfo: JSON.stringify({
                   row
                  }),
        },
      });
    },
    // 获取选中的班级列表
    getGradeList2(row) {
      this.groupForm.projectIdList = [];
      this.$post("/edu/project/paper/project", {
        paperId: row.paperId,
        paperTheme: row.paperTheme,
        paperActivityId: row.paperActivityId
      }).then((ret) => {
        if (ret.status == "0") {
          ret.data.forEach((item, index) => {
            this.groupForm.projectIdList.push(item.projectId);
          });
          this.groupForm.pushType = row.pushType;
        }
      });
    },
    /* 导出 */
    exportPaper(paperId) {
      this.$post("/biz/paper/exportPaper", {
        paperId,
      })
        .then((ret) => {
          window.open(ret.data);
        })
        .catch((err) => {
          return;
        });
    },
    /* 导出 - word */
    exportWord(paperId) {
      this.$post("/biz/paper/exportPaperWord", {
        paperId,
      })
        .then((ret) => {
          window.open(ret.data);
        })
        .catch((err) => {
          return;
        });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
    dropDownClick(e) {
      console.log(e.name, e.val)
    }
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh == true) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scope>
.el-textarea__inner {
  min-height: 20rem !important;
  resize: none;
}
/* .itemLabel {
  width: 180px;
  // background: #000;
} */
.itemLabel2 {
  width: 10px;
  // background: #000;
}

.flex {
  display: flex;
}
</style>
